import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_alert = _resolveComponent("ion-alert")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_tabs, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet),
          (_ctx.showLevelRow)
            ? (_openBlock(), _createBlock(_component_ion_row, {
                key: 0,
                class: "level"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    size: "6",
                    class: "ion-padding ion-justify-content-center level-container"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Estas en el  "),
                      _createVNode(_component_ion_text, { class: "level-value" }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Nivel " + _toDisplayString(_ctx.level), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, { size: "6" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        class: "upgrade-button",
                        expand: "block",
                        shape: "round",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setOpen(true)))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Mejorar cuenta ")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_alert, {
                        "is-open": _ctx.isOpen,
                        header: "Subscripción Beta",
                        message: "Para poder acceder a todas las funcionalides debes realizar la subscripcíon al servicio. Una vez realizado el pago, contactar al administrador para habilitar el nuevo nivel.",
                        buttons: _ctx.alertButtons,
                        onDidDismiss: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setOpen(false)))
                      }, null, 8, ["is-open", "buttons"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}