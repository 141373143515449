import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/tabs/TabsPage.vue';
import { getAuth } from 'firebase/auth';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    component: () => import('@/views/Auth/LoginViewPage.vue')
  },
  {
    path: '/register/:code',
    component: () => import('@/views/Auth/RegisterViewPage.vue'),
    beforeEnter: async (to, from, next) => {
      const code = to.params.code;
      if (code) {
        next(); // Si el código es válido, permite el acceso a la vista de registro
      } else {
        // Si el código no es válido, redirige a otra página o muestra un mensaje de error
        next('/error');
      }
    },
  },
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/error',
    component: () => import('@/views/Auth/ErrorViewPage.vue')
  },
  {
    path: '/content/',
    component: TabsPage,
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: '',
        redirect: '/content/home'
      },
      {
        path: 'home',
        component: () => import('@/views/tabs/Tab1Page.vue')
      },
      {
        path: 'generator',
        component: () => import('@/views/tabs/Tab2Page.vue')
      },
      {
        path: 'slides',
        component: () => import('@/views/slides/SlidesPage.vue')
      },
      {
        path: 'slides/matrix',
        component: () => import('@/views/slides/options/MatrixViewPage.vue')
      },
      {
        path: 'slides/options',
        component: () => import('@/views/slides/options/OptionsViewPage.vue')
      },
      {
        path: 'my-account',
        component: () => import('@/views/tabs/Tab3Page.vue')
      },
      {
        path: 'laboratory',
        component: () => import('@/views/laboratory/LaboratoryPage.vue'),
      },
      {
        path: 'library',
        component: () => import('@/views/library/LibraryPage.vue'),
      },
      {
        path: 'laboratory/:id',
        component: () => import('@/views/laboratory/LaboratoryViewPage.vue'),
      },
      {
        path: 'combos',
        component: () => import('@/views/combos/CombosPage.vue'),
      },
      {
        path: 'combos/:id',
        component: () => import('@/views/combos/ComboViewPage.vue'),
      },
      {
        path: 'my-favorites',
        component: () => import('@/views/FavoritesPage.vue')
      },
      {
        path: 'qr-generator',
        component: () => import('@/views/QrGeneratorPage.vue')
      },
      {
        path: 'change-password',
        component: () => import('@/views/Auth/ChangePasswordPage.vue')
      },
      {
        path: 'manage-users',
        component: () => import('@/views/ManageUsersPage.vue')
      },
      {
        path: 'manage-users/:uid',
        component: () => import('@/views/UserEditPage.vue'),
      },
      {
        path: 'manage-tricks',
        component: () => import('@/views/ManageTricksPage.vue')
      },
      {
        path: 'feedback',
        component: () => import('@/views/feedback/FeedbackPage.vue')
      },
      {
        path: 'blacklist',
        component: () => import('@/views/BlacklistPage.vue')
      }
    ]
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/login'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Validación de inicio de sesión para acceder a content
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authRequired)) {
    if (getAuth().currentUser) {
      next();
    } else {
      alert('Debes iniciar sesión para acceder a esta página');
      next({
        path: '/',
      });
    }
  } else {
    next();
  }
});

export default router
