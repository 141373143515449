import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyA4eHin4-m1H_VD-UjZTJuipZj65hnVrqg",
  authDomain: "octa-587f7.firebaseapp.com",
  databaseURL: "https://octa-587f7-default-rtdb.firebaseio.com/",
  projectId: "octa-587f7",
  storageBucket: "octa-587f7.appspot.com",
  messagingSenderId: "658362032949",
  appId: "1:658362032949:web:b5b94e11c006398b26627c",
  measurementId: "G-HP355Q3J7X"
};

initializeApp(firebaseConfig);

import store from '@/store'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

const app = createApp(App)
  .use(store)
  .use(IonicVue)
  .use(router);
  
router.isReady().then(() => {
  app.mount('#app');
});