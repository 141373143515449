
import { IonText, IonRow, IonCol, IonTabs, IonPage, IonRouterOutlet, IonButton, IonAlert } from '@ionic/vue';
import { chevronForwardOutline } from 'ionicons/icons';

import { useStore } from "vuex";
import { computed, defineComponent, ref, watch} from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: "TabsComponent",
  components: {
    IonPage, IonText, IonRow, IonCol, IonTabs, IonRouterOutlet, IonButton, IonAlert
  },

  
  setup() {

    const store = useStore()
    const route = useRoute();
    const showLevelRow = ref(true);
    const level = ref('');
    const role = ref('');
    const isOpen = ref(false);

    const setOpen = (state: boolean) => {
      console.log(state)
    isOpen.value = state;
  };

  const getLevel = computed(() => {
      return store.getters.getLevel;
    });
    console.log('in tabs', getLevel.value)
  
    level.value = getLevel.value;

    watch(
  () => route.fullPath,
  (newRoute) => {
    const hideComponent = newRoute.includes('/content/generator') || newRoute.includes('/content/my-account') || newRoute.includes('/content/slides');
    
    console.log(hideComponent);

    showLevelRow.value = !hideComponent;
  }
);


    

    const getRole = computed(() => {
      return store.getters.getRole;
    });
    role.value = getRole.value;

    const isAdmin = computed(() => {
      return role.value === 'admin' ? true : false;
    })

    const alertButtons = [
      {
        text: 'Volver',
        role: 'cancel',
        handler: () => {
          console.log('Alert canceled');
        },
      },
      {
        text: 'Ir al pago',
        role: 'confirm',
        handler: () => {
          const externalURL = 'https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=687WT3REWYQ9G';
          window.open(externalURL, '_system');
          console.log('Alert confirmed');
        },
      },
    ];

    return { level, role, isAdmin, chevronForwardOutline, showLevelRow, alertButtons, isOpen, setOpen }
  }
})
